// Получаем ссылки на DOM-элементы интерфейса
const signInBtn = document.getElementById('auth-btn');
const authCard = document.getElementById('auth-card');
/**
 * Основное приложение (HTMl Element)
 * @type {Element}
 */
const app = document.getElementById('app');
const signOutBtn = document.getElementById('sign-out');
const authSpinner = document.querySelector('.spinner-container');
const usernameOutput = document.getElementById('user-name');
const userInfo = document.getElementById('user-info');
const userOperations = document.getElementById('user-operations');
/**
 * Логика для перехода на главную (везде работает)
 * @type {Element}
 */
const goToMainMenuBtn = document.getElementById('go-to-main-menu'); // Элемент кнопки перехода в главное меню
window.goToMainMenuBtn = goToMainMenuBtn;
// Импорт необходимых функций и объектов для работы с авторизацией через Firebase
import { auth, provider } from './firebase.js';
import { signInWithRedirect, getRedirectResult, onAuthStateChanged, signOut, signInWithPopup } from "firebase/auth";

/**
 * Функция для авторизации пользователя через Firebase.
 */
async function signInUser() {
    try {
        // Авторизация пользователя через всплывающее окно
        const result = await signInWithPopup(auth, provider);
        // Данные пользователя после успешной авторизации
        const user = result.user;
        console.log('User signed in: ', user);
    } catch (error) {
        // Обработка возможных ошибок при авторизации
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.email;
        const credential = error.credential;
        console.log(errorCode, errorMessage, email, credential);
    }
}
// async function signInUser() {
//     try {
//         // Авторизация пользователя
//         await signInWithRedirect(auth, provider);
//
//         // Получаем данные пользователя после редиректа
//         await getRedirectResult(auth);
//
//     } catch (error) {
//         // Обработка возможных ошибок при авторизации
//         const errorCode = error.code;
//         const errorMessage = error.message;
//         const email = error.email;
//         const credential = error.credential;
//         console.log(errorCode, errorMessage, email, credential);
//     }
// }

/**
 * Функция для показа интерфейса авторизации
 */
function showAuthInterface() {
    signInBtn.classList.remove('hidden');
    authCard.classList.remove('hidden');
    signOutBtn.classList.add('hidden'); // Убираем кнопку для выхода из аккаунта

}

/**
 * Функция для скрытия интерфейса авторизации
 */
function hideAuthInterface() {
    signInBtn.classList.add('hidden');
    authCard.classList.add('hidden');
    signOutBtn.classList.remove('hidden'); // Делаем возможность выйти из аккаунта (кнопка)
}
// При загрузке документа проверяем статус авторизации пользователя

document.addEventListener('DOMContentLoaded', () => {
    onAuthStateChanged(auth, async (user) => {
        authSpinner.classList.add('hidden');
        authCard.classList.remove('hidden');
        loadBookingFromPath();

        if (user) {
            if (config.forbiddenEmails.some(email => user.email === email)) {
                alert(`Нельзя входить с ${user.email}, войдите с личной почты`);
                await signOut(auth);
                showAuthInterface();
                return;
            }

            window.user = user;
            hideAuthInterface();
            await toggleMainMenu('show');
            userInfo.classList.remove('hidden');
            userOperations.classList.remove('hidden');
        } else {
            userInfo.classList.add('hidden');
            userOperations.classList.add('hidden');
            window.user = null;
            showAuthInterface();
        }

        usernameOutput.textContent = user ? user.displayName : '';
    });
});
// Обработчики событий для кнопок входа и выхода
signInBtn.addEventListener('click', async () => {
    await signInUser();
});

signOutBtn.addEventListener('click', async () => {
    try {
        await signOut(auth);
        console.log('User signed out');
        app.classList.add('hidden');
        showAuthInterface();
    } catch (error) {
        console.error('Sign out error', error);
    }
});

// signOutBtn.addEventListener('click', async () => {
//     try {
//         await signOut(auth);
//         console.log('User signed out');
//         // Скрываем весь контент кроме авторизации
//         app.classList.add('hidden');
//         showAuthInterface();
//     } catch (error) {
//         console.error('Sign out error', error);
//     }
// });

/**
 Обработчик события "click" для кнопки перехода в главное меню.
 */
goToMainMenuBtn.addEventListener("click", async () => {
    await toggleMainMenu('show'); // Скрываем все элементы, кроме главного меню
});